<template>
    <div>
    <a-modal
      title="领卡"
      :visible="modalTakeVisible"
      @cancel="modalTakeVisible = false"
      :footer="null"
      width="600px"
    >
    <a-form
        :form="takeCardForm"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleTakeSubmit"
      >
      <a-row>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="申请人">
                <a-button block style="text-align: left">
                  {{detail.applicant}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="职能部门">
                <a-button block style="text-align: left">
                  {{detail.applicantDept}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="卡号">
                <a-input
                  v-decorator="[
                    'cardNumber',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="领卡时金额(元)">
                <a-input-number
                  :formatter="$inputNumberFormat"
                  v-decorator="[
                    'cardAmount',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>

        <div class="center">
          <a-space>
            <a-button @click="modalTakeVisible = false">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
    <a-modal
      title="还卡"
      :visible="modalReturnVisible"
      @cancel="modalReturnVisible = false"
      :footer="null"
      width="600px"
    >
    <a-form
        :form="returnCardForm"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleReturnSubmit"
      >
      <a-row>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="申请人">
                <a-button block style="text-align: left">
                  {{detail.applicant}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="职能部门">
                <a-button block style="text-align: left">
                  {{detail.applicantDept}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
                <a-form-item label="领卡时间">
                  <a-button block style="text-align: left">
                    {{detail.applyTime}}
                  </a-button>
                </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="卡号">
                <a-button block style="text-align: left">
                  {{detail.cardNumber}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="领卡时金额(元)">
                <a-button block style="text-align: left">
                  {{detail.cardAmount}}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="24" :md="24" :sm="24">
              <a-form-item label="还卡时金额(元)">
                <a-input-number
                  :formatter="$inputNumberFormat"
                  v-decorator="[
                    'cardBalance',
                    { rules: [{ required: true, message: '请输入！' }] },
                  ]"
                  style="width: 100%"
                />
              </a-form-item>
            </a-col>
          </a-row>

        <div class="center">
          <a-space>
            <a-button @click="modalReturnVisible = false">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
      <Pane>
      </Pane>
      <a-card class="container">
        <div>
    <a-row>
      <a-col :span="22">
        <a-form-model
          layout="inline"
          :colon="false"
          @keyup.enter.native="query"
        >
          <a-form-model-item>
            <a-input v-model="form.applicant" placeholder="申请人" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.cardNumber" placeholder="卡号" />
          </a-form-model-item>
          <a-form-item>
              <a-select
                style="width: 160px"
                placeholder="职能部门"
                v-model="form.applicantDeptId"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
          </a-form-item>
          <a-form-model-item>
              <a-select
                v-model="form.status"
                placeholder="借用状态"
                style="width: 150px"
              >
                <a-select-option
                  v-for="item in dineCardStatusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>

    <Padding />
    <a-table
      v-if="tableList.length > 0"
      bordered
      :dataSource="tableList"
      @change="onChange"
      :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
      childrenColumnName="orgs"
      :indentSize="24"
      :defaultExpandAllRows="true"
      rowKey="id"
    >
      <a-table-column title="序号" width="50px" align="center">
        <template slot-scope="text, row, index">
            {{ index + 1 }}
        </template>
      </a-table-column>
      <a-table-column
        title="申请人"
        data-index="applicant"
      ></a-table-column>
      <a-table-column
        title="职能部门"
        data-index="applicantDept"
      ></a-table-column>
      <a-table-column
        title="预计用卡时间"
        data-index="useDate"
      ></a-table-column>
      <a-table-column
        title="领卡时间"
        data-index="applyTime"
      ></a-table-column>
      <a-table-column
        title="卡号"
        data-index="cardNumber"
      ></a-table-column>
      <a-table-column
        title="领卡时金额(元)"
        data-index="cardAmount"
      ></a-table-column>
      <a-table-column
        title="交还时间"
        data-index="returnDate"
      ></a-table-column>
      <a-table-column
        title="交还时金额(元)"
        data-index="cardBalance"
      ></a-table-column>
      <a-table-column
        title="使用金额(元)"
      >
        <template slot-scope="text">
          <span v-if="text.cardBalance && text.cardBalance !== 0">{{ text.cardAmount - text.cardBalance }}</span>
        </template>
      </a-table-column>
      <a-table-column title="借用状态" data-index="status" align="center">
        <template slot-scope="status">
          <DataDictFinder dictType="dineCardStatus" :dictValue="String(status)" />
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text">
          <a-space>
            <a
              v-if="text.status=='reviewing' || text.status=='approved'"
              href="#"
              @click.prevent="takeCard(text)"
              >领卡</a
            >
            <a
              v-if="text.status=='received'"
              href="#"
              @click.prevent="returnCard(text)"
              >还卡</a
            >
          </a-space>
        </template>
      </a-table-column>
    </a-table>
  </div>
      </a-card>
    </div>
  </template>
  
  <script>
  import organization from "@/mixins/organization";
  import {fetchList, received, returned} from "@/api/oa/meal-card";
  import { mapGetters } from "vuex";
  export default {
    data() {
        return {
          modalTakeVisible: false,
          modalReturnVisible: false,
          current: 1,
          pageSize: 10,
          total: 0,
          form: {},
          takeCardForm: this.$form.createForm(this),
          returnCardForm: this.$form.createForm(this),
          tableList: [],
          detail: {}
        };
    },
    mixins: [organization],
    computed: {
    ...mapGetters("setting", ["findDataDict"]),
    dineCardStatusList() {
      return this.findDataDict("dineCardStatus");
    },
  },
    mounted() {
      this.getList();
    },
  
    methods: {
      getList() {
        const { current, pageSize, form } = this;
        console.log(form)
        fetchList({
          pageNum: current,
          pageSize: pageSize,
          ...form,
        })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.tableList = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
      },
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.form = {};
        this.current = 1;
        this.getList();
      },
      takeCard(text) {
        this.modalTakeVisible = true;
        this.takeCardForm.resetFields();
        this.detail = text;
      },
      returnCard(text) {
        this.modalReturnVisible = true;
        this.returnCardForm.resetFields();
        this.detail = text;
      },
      handleTakeSubmit(e) {
          e.preventDefault();
          this.takeCardForm.validateFields((err, values) => {
          if (!err) {
            console.log(values)
            received({
              ...this.detail,
              cardNumber: values.cardNumber,
              cardAmount: values.cardAmount,
            })
            .then(() => {
              this.modalTakeVisible = false;
              this.getList();
            })
          }
        })
      },
      handleReturnSubmit(e) {
          e.preventDefault();
          this.returnCardForm.validateFields((err, values) => {
          if (!err) {
            console.log(values)
            returned({
              ...this.detail,
              cardBalance: values.cardBalance,
            })
            .then(() => {
              this.modalReturnVisible = false;
              this.getList();
            })
          }
        })
      }
    },
  };
  </script>